import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

export type GenSelectProps = {
    inc: () => void,
    createMarker: (long: number, lat: number) => void,
    allowNext: boolean,
}

export default function GenSelect({ inc, createMarker, allowNext }: GenSelectProps) {
    const [searchText, setText] = useState("");

    function geoCode(placeId: any) {
        var geocoder = new google.maps.Geocoder();
        geocoder.geocode({ placeId: placeId.place_id }, (out: any) => {
            setText(out[0].formatted_address)
            createMarker(out[0].geometry.location.lng(), out[0].geometry.location.lat());
        });
    }

    useEffect(() => {
        var autocomplete = new google.maps.places.Autocomplete(document.getElementById("autocompleteInput"));
        autocomplete.addListener("place_changed", () => geoCode(autocomplete.getPlace()));
        autocomplete.setFields(["address_components", "place_id"]);
    }, [])

    return (
        <React.Fragment>
            <div className="pt-4" >
                <h3>Step 1: </h3>
            </div>
            <div className="pt-2" >
                <h5>Select a location for your 3D map</h5>
            </div>
            <div className="pt-2" >
                Please drop a place marker as the center location of your ideal 3D map
            </div>
            <div className="pt-4 w-75" >
                <Form.Control id="autocompleteInput" type="text" placeholder="Search for a location..." onChange={evt => setText(evt.target.value)} value={searchText} />
            </div>
            <div className="pt-4 ml-auto mr-5" >
                <Button className="ml-auto" onClick={() => inc()} disabled={allowNext} >Next</Button>
            </div>
        </React.Fragment>
    )
}