import axios from "axios";
import { CameraFlyTo, Viewer, Globe } from "resium";
import React, { useState, useEffect } from "react";
import * as Cesium from "cesium";

import { Form, Button } from "react-bootstrap";

import "./css/admin.css";

type AdminProps = {

}

type AdminState = {
    password: string,
    login: boolean,
    curLogin: boolean,
}

export default class Admin extends React.Component<AdminProps, AdminState> {
    state = {
        password: "",
        login: false,
        curLogin: false,
    }

    login() {
        this.setState({ curLogin: true });
        axios.post("/api/admin", { password: this.state.password }).then(res => {
            if (res.data.valid == true) {
                this.setState({ login: true, curLogin: false });
            } else {
                this.setState({ login: false, curLogin: false });
                alert("password incorrect")
            }
        }).catch(err => {
            this.setState({ curLogin: false });
            alert("An error has occured. Please try again.");
        })
    }

    render() {
        return <>{!this.state.login ? <div className="d-flex flex-column align-items-center pt-2 h-100 bg-dark" >
            <div>
                <Form.Control placeholder="enter password" type="password" onChange={evt => { this.setState({ password: evt.target.value }) }} value={this.state.password} />
            </div>
            <div className="mt-2" >
                <Button type="primary" onClick={() => this.login()} >{this.state.curLogin ? "Logging in..." : "Login"}</Button>
            </div>
        </div> : <MainViewer />}</>
    }
}

function MainViewer() {
    const [id, setId] = useState("");
    const [lat, setLat] = useState(0);
    const [long, setLong] = useState(0);
    const [size, setSize] = useState(0);
    const [terExag, setExag] = useState(0);
    const [loaded, setLoaded] = useState(false);
    const [globe, setGlobe] = useState<Cesium.Globe | undefined>();

    function loadData() {
        axios.get("/api/admin", { params: { id: id } }).then((resp) => {
            setLat(resp.data.lat);
            setLong(resp.data.long);
            setSize(resp.data.size);
            setExag(resp.data.terExag);
            setLoaded(true);
        })
    }

    useEffect(() => {
        if (globe && loaded) {
            globe.clippingPlanes = new Cesium.ClippingPlaneCollection({
                planes: [
                    new Cesium.ClippingPlane(new Cesium.Cartesian3(1, 0, 0), size / 2),
                    new Cesium.ClippingPlane(new Cesium.Cartesian3(-1, 0, 0), size / 2),
                    new Cesium.ClippingPlane(new Cesium.Cartesian3(0, 1, 0), size / 2),
                    new Cesium.ClippingPlane(new Cesium.Cartesian3(0, -1, 0), size / 2)
                ],

                enabled: true,
                modelMatrix: Cesium.Transforms.eastNorthUpToFixedFrame(Cesium.Cartesian3.fromDegrees(long, lat)),
                unionClippingRegions: true,

                edgeWidth: 1,
                edgeColor: Cesium.Color.WHITE
            });
        }
    }, [globe, loaded])

    return (
        <div className="d-flex flex-column align-items-center pt-2 h-100 bg-dark text-white" >
            <div>
                <h1>Welcome</h1>
            </div>
            <div>
                <Form.Control placeholder="id" type="text" onChange={evt => setId(evt.target.value)} value={id} />
            </div>
            <div className="mt-2" >
                <Button variant="primary" onClick={() => loadData()} >Submit</Button>
            </div>
            <div className="d-flex justify-content-center" >
                <div className="d-flex flex-column align-items-center mt-2" >
                    <div>
                        <h3>
                            Data
                        </h3>
                    </div>
                    <div className="d-flex align-items-center" >
                        <div>
                            Latitude: {lat}
                        </div>
                        <div className="ml-2" >
                            Long: {long}
                        </div>
                        <div className="ml-2" >
                            Size: {size}m
                        </div>
                        <div className="ml-2" >
                            Terrain Exaggeration: {terExag}x
                        </div>
                    </div>
                    <div >
                        <Viewer id="adminViewer" >
                            <Globe ref={ref => setGlobe(ref?.cesiumElement)} />
                            {loaded ? <CameraFlyTo destination={Cesium.Cartesian3.fromDegrees(long, lat, 2000)} /> : null}
                        </Viewer>
                    </div>
                </div>
            </div>
        </div>
    )
}