import React from "react";

import { Fas } from "./Utils";


export default function Marker({ lng, lat }: { lng: number, lat: number }) {
    return (
        <div>
            <Fas type="map-marker-alt" className="marker" />
        </div>
    )
}