import React, { useEffect, useState } from "react";
import * as Cesium from "cesium";

import { CameraFlyTo, Viewer, Globe, ScreenSpaceCameraController } from "resium";
import GoogleMapReact from "google-map-react";

import "./css/app.css";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";

import Start from "./views/Start";
import GenSelect from "./views/GenSelect";
import Rect from "./views/Rect";
import Marker from "./Marker";
import axios from "axios";

type AppProps = {

}

enum View {
    Start = 0,
    GenSelect = 1,
    Rect = 2
}

var terrain: any = undefined;
var imagery: any = undefined;

Cesium.Ion.defaultAccessToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI3MTUxYTI5MC03MmU0LTQ1OTYtYWViNi03MzYyM2VjYzViMTQiLCJpZCI6Mjk4MDMsInNjb3BlcyI6WyJhc2wiLCJhc3IiLCJhc3ciXSwiaWF0IjoxNTkyNjk1MTMxfQ.QtDLw3-jDp1V4ptXZpzQb0pPoceINxNdITPeyVmxB4s"

export default function App(props: AppProps) {

    const [view, setView] = useState<View>(View.Start);
    const [mk, setMarker] = useState<{ long: number, lat: number }>({ long: 69420, lat: 69420 });

    const [globe, setGlobe] = useState<Cesium.Globe | undefined>();

    const [size, setSize] = useState(5000);
    const [terrainExag, setTerExag] = useState(1);

    const [flyToDone, setFlyToDone] = useState(true);

    // View-Related Functions
    function incView() {
        setView(view + 1);
    }
    function back() {
        setFlyToDone(false);
        setView(view - 1);
    }

    function submit() {
        axios.post("/api/add", { long: mk.long, lat: mk.lat, terExag: terrainExag, size: size }).then((response) => {
            if (response.data.success === true) {
                window.location.assign("https://www.qmine3d.com/?post_type=product&p=3146&orderid=" + response.data.id);
            } else {
                alert("Submission Unsuccessful. Please Try Again.");
            }
        })
    }

    function calcView(view: number) {
        switch (view) {
            case View.Start:
                return <Start inc={() => incView()} />
            case View.GenSelect:
                return <GenSelect inc={() => incView()} createMarker={(long, lat) => createMarker(long, lat)} allowNext={mk.lat == 69420} />
            case View.Rect:
                return <Rect setSize={(...params) => setSize(...params)} setTerExag={(...params) => updateTerExag(...params)} back={() => back()} submit={() => submit()} />
        }
    }

    function updateTerExag(exag: number) {
        setView(View.GenSelect);
        setTerExag(exag);
        setView(View.Rect)
    }

    // Map Functions
    function createMarker(long: number, lat: number) {
        if (view === View.GenSelect) {
            setMarker({ long: long, lat: lat });
        }
    }


    // Viewer Functions
    useEffect(() => {
        terrain = Cesium.createWorldTerrain();
        imagery = Cesium.createWorldImagery();
    }, []);

    useEffect(() => {
        if (globe && globe.clippingPlanes) {
            globe.clippingPlanes.get(0).distance = size / 2;
            globe.clippingPlanes.get(1).distance = size / 2;
            globe.clippingPlanes.get(2).distance = size / 2;
            globe.clippingPlanes.get(3).distance = size / 2;
        }
    }, [size, globe])

    useEffect(() => {
        if (globe) {
            setFlyToDone(false);
            globe.clippingPlanes = new Cesium.ClippingPlaneCollection({
                planes: [
                    new Cesium.ClippingPlane(
                        new Cesium.Cartesian3(1, 0, 0),
                        size / 2
                    ),
                    new Cesium.ClippingPlane(
                        new Cesium.Cartesian3(-1, 0, 0),
                        size / 2
                    ),
                    new Cesium.ClippingPlane(
                        new Cesium.Cartesian3(0, 1, 0),
                        size / 2
                    ),
                    new Cesium.ClippingPlane(
                        new Cesium.Cartesian3(0, -1, 0),
                        size / 2
                    )
                ],
    
                enabled: true,
                modelMatrix: Cesium.Transforms.eastNorthUpToFixedFrame(Cesium.Cartesian3.fromDegrees(mk.long, mk.lat)),
                unionClippingRegions: true,
    
                edgeWidth: 1,
                edgeColor: Cesium.Color.WHITE
            })
        }
    }, [globe])

    return (
        <React.Fragment>
            <div id="menu" className={`text-center d-flex flex-column align-items-center p-3`} >
                {calcView(view)}
            </div>
            {view === View.Start || view === View.GenSelect ?
                <div id="map" >
                    <GoogleMapReact
                        bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_API_KEY || "" }}
                        defaultCenter={{ lat: 0, lng: 0 }}
                        defaultZoom={1}
                        onClick={evt => createMarker(evt.lng, evt.lat)}
                        options={() => ({ mapTypeControl: true })}
                    >
                        {mk.lat !== 69420 ? <Marker lng={mk.long} lat={mk.lat} /> : null}
                    </GoogleMapReact>
                </div>
                : <Viewer timeline={false} animation={false} homeButton={false} baseLayerPicker={false} navigationHelpButton={true} sceneModePicker={false} terrainProvider={terrain} imageryProvider={imagery} terrainExaggeration={terrainExag} >
                    {!flyToDone ? <CameraFlyTo onComplete={() => setFlyToDone(true)} destination={Cesium.Cartesian3.fromDegrees(mk.long, mk.lat, 10000)} /> : null}
                    <Globe ref={ref => setGlobe(ref?.cesiumElement)} />
                    <ScreenSpaceCameraController enableCollisionDetection={false} />
                </Viewer>}
        </React.Fragment>
    )
}