import React from "react";

import Button from "react-bootstrap/Button";

export type StartProps = {
    inc: () => void
}

export default function Start(props: StartProps) {
    return (
        <React.Fragment>
            <div className="pt-2" >
                <h2>Select an area for 3D printing in 2 easy steps!</h2>
            </div>
            <div className="pt-2" >
                <Button variant="primary" onClick={props.inc} >
                    Get Started
                </Button>
            </div>
        </React.Fragment>
    )
}