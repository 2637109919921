import axios from "axios";
import { CameraFlyTo, Viewer, Globe, ScreenSpaceCameraController } from "resium";
import React, { useState, useEffect } from "react";
import * as Cesium from "cesium";

const URLParams = new URLSearchParams(window.location.search);

var terrain: Cesium.TerrainProvider;
var imagery: Cesium.ImageryProvider;

Cesium.Ion.defaultAccessToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI3MTUxYTI5MC03MmU0LTQ1OTYtYWViNi03MzYyM2VjYzViMTQiLCJpZCI6Mjk4MDMsInNjb3BlcyI6WyJhc2wiLCJhc3IiLCJhc3ciXSwiaWF0IjoxNTkyNjk1MTMxfQ.QtDLw3-jDp1V4ptXZpzQb0pPoceINxNdITPeyVmxB4s"

export default function Preview() {
    const [terrainExaggeration, setTerExag] = useState(0);
    const [long, setLong] = useState(0);
    const [lat, setLat] = useState(0);
    const [size, setSize] = useState(0);

    const [loaded, setLoaded] = useState(false);
    const [loadingMessage, setLoading] = useState("Loading...")

    const [globe, setGlobe] = useState<Cesium.Globe | undefined>();

    useEffect(() => {
        terrain = Cesium.createWorldTerrain();
        imagery = Cesium.createWorldImagery();

        axios.get("/api/fetchdata", { params: { id: URLParams.get("orderid") } }).then((response) => {
            console.log(response.data)
            if (response.data !== "error" && response.data !== "invalid") {
                const data = response.data;
                setLong(Number(data.long));
                setLat(Number(data.lat));
                setTerExag(Number(data.terExag));
                setSize(Number(data.size));
                setLoaded(true);
            } else {
                setLoading("Invalid Order");
            }
        })
    }, []);

    useEffect(() => {
        if (globe) {
            globe.clippingPlanes = new Cesium.ClippingPlaneCollection({
                planes: [
                    new Cesium.ClippingPlane(
                        new Cesium.Cartesian3(1, 0, 0),
                        size / 2
                    ),
                    new Cesium.ClippingPlane(
                        new Cesium.Cartesian3(-1, 0, 0),
                        size / 2
                    ),
                    new Cesium.ClippingPlane(
                        new Cesium.Cartesian3(0, 1, 0),
                        size / 2
                    ),
                    new Cesium.ClippingPlane(
                        new Cesium.Cartesian3(0, -1, 0),
                        size / 2
                    )
                ],

                enabled: true,
                modelMatrix: Cesium.Transforms.eastNorthUpToFixedFrame(Cesium.Cartesian3.fromDegrees(long, lat)),
                unionClippingRegions: true,

                edgeWidth: 1,
                edgeColor: Cesium.Color.WHITE
            })
        }
    }, [globe])

    return (
        <>
            {loaded ? <Viewer id="a" timeline={false} animation={false} homeButton={false} baseLayerPicker={false} navigationHelpButton={false} sceneModePicker={false} terrainProvider={terrain} imageryProvider={imagery} terrainExaggeration={terrainExaggeration} >
                <CameraFlyTo destination={Cesium.Cartesian3.fromDegrees(long, lat, 10000)} />
                <Globe ref={ref => setGlobe(ref?.cesiumElement)} />
                <ScreenSpaceCameraController enableCollisionDetection={false} />
            </Viewer> :
                <div className="w-100 h-100 d-flex justify-content-center align-items-center" >
                    <div>
                        {loadingMessage}
                    </div>
                </div>
            }
        </>
    )
}