import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";

import Form from "react-bootstrap/Form";

type RectProps = {
    setSize: React.Dispatch<React.SetStateAction<number>>,
    setTerExag: (exag: number) => void,
    back: () => void,
    submit: () => void,
}

export default function Rect({ setSize, back, setTerExag, submit }: RectProps) {
    const [sizeStr, setSizeStr] = useState<string>("5000");
    const [terExagView, setTerExagView] = useState(1);

    useEffect(() => {
        setSize(Number(sizeStr))
    }, [sizeStr])

    useEffect(() => {
        setTerExag(terExagView)
    }, [terExagView])

    return (
        <React.Fragment>
            <div className={`d-flex flex-column w-100 h-100 align-items-center`} >
                <div className="pt-4" >
                    <h3>Select Area</h3>
                </div>
                <div className="pt-2" >
                    Area Size and Vertical Exaggeration:
                </div>
                <div className="mt-2" >
                    width/length:
                </div>
                <div className="mt-2 w-75 d-flex align-items-center" >
                    <div className="flex-grow-1 mr-2" >
                        <Form.Control type="number" className="text-center" value={sizeStr} onChange={evt => setSizeStr(evt.target.value)} min="0" />
                    </div>
                    <div>
                        m
                    </div>
                </div>

                <div className="mt-4" >
                    Terrain Exaggeration:
                </div>
                <div className="mt-2 btn-group d-flex w-75 align-items-center" role="group" >
                    <Button variant={terExagView === 1 ? "success" : "primary"} onClick={() => setTerExagView(1)} >1.0x</Button>
                    <Button variant={terExagView === 1.5 ? "success" : "primary"} onClick={() => setTerExagView(1.5)} >1.5x</Button>
                    <Button variant={terExagView === 2 ? "success" : "primary"} onClick={() => setTerExagView(2)} >2.0x</Button>
                    <Button variant={terExagView === 2.5 ? "success" : "primary"} onClick={() => setTerExagView(2.5)} >2.5x</Button>
                    <Button variant={terExagView === 3 ? "success" : "primary"} onClick={() => setTerExagView(3)} >3.0x</Button>
                </div>

                <div className="pt-4 d-flex align-items-center justify-content-between w-75" >
                    <Button onClick={() => back()} >Back</Button>
                    <Button onClick={() => submit()} >Submit</Button>
                </div>
            </div>
        </React.Fragment>
    )
}