import React from 'react';
import ReactDOM from 'react-dom';
import Admin from './Admin';
import App from './App';
import Preview from "./Preview";

const searchParams = new URLSearchParams(window.location.search);


ReactDOM.render(
  <React.StrictMode>
    {route()}
  </React.StrictMode>,
  document.getElementById('root')
);

function route() {
  if(searchParams.get("administrator")) {
    return <Admin />
  } else {
    if (searchParams.get("preview")) {
      return <Preview />
    } else {
      return <App />
    }
  }
}